import React from 'react';


export function CommonCompenentDataMarket(props) {
    const {
        infoDataMarket,
        description,
    } = props;
    return (
        <>
            <abbr className="text-decoration-none" title={description}>{infoDataMarket['title']}<span className="fs-small text-secondary">{infoDataMarket['sub_title']}</span></abbr>
        </>
    )
}


export function CompenentDataMarketFirstSecondExtraTime(props) {
    const {
        infoDataMarket,
        description,
    } = props;
    return (
        <>
            <abbr className="text-decoration-none" title={description}>{infoDataMarket['title']}<sup> <span className="fs-small">  {infoDataMarket['first_sub_title']}</span> </sup> <span className="fs-small text-secondary">{infoDataMarket['second_sub_title']}</span></abbr>
        </>
    )
}

export function CompenentDataMarketDefault(props) {
    const {
        infoDataMarket,
        description,
    } = props;
    return (
        <>
            <abbr className="text-decoration-none" title={description}>{infoDataMarket['text']}</abbr>
        </>
    )
}





function TextUp(props) {
    const { text } = props;

    return (<sup> <span className="fs-small">  {text}</span> </sup>);
}


function TextDownGray(props) {
    const { text } = props;
    return (<span className="fs-small text-secondary"> {text}</span>);
}


export function CompenentDataMarketDynamic(props) {
    const {
        infoDataMarket,
        description,
    } = props;

    //const keysComponent = Object.keys(infoDataMarket['info']);
    //console.log('infoDataMarket:', infoDataMarket);
    return (
        <>
            <abbr className="text-decoration-none" title={description}>
                {
                    infoDataMarket['info'].map((item, index) => {


                        switch (item['type']) {

                            case 'up_small':
                                return (
                                    <React.Fragment key={index}>
                                        <TextUp text={item['value']} />
                                    </React.Fragment>
                                );
                            case 'down_small_gray':
                                return (
                                    <React.Fragment key={index}>
                                        <TextDownGray text={item['value']} />
                                    </React.Fragment>
                                );
                            default:
                                return (
                                    <React.Fragment key={index}>
                                        {item['value']}
                                    </React.Fragment>
                                );
                        }
                    })
                }
            </abbr>
        </>
    )
}