import '../assets/css/layout-surebet-mobile.css';
import { orderLoop, styleDataMarket, NameBetHomeRenamed } from '../global/GlobalVariables';
import { Tooltip } from 'react-tooltip';
import GeneratorLinkSurebet from "./functions/GeneratorLinkExtension";

const estrela = `${process.env.PUBLIC_URL}/images/icone-etoile-verte.png`;

function LayoutSurebetMobile(props) {

    const {
        data,
        value,
        statusMode,
        handleClick,
        newKeySymbol,        
        eventTrash,
        statusTrash,
        warningTimeSurebet,
    } = props;

    return (
        <div className={`container-surebet-mobile`}>
            <div className="d-flex align-items-end custom-pt-surebet-mobile custom-p-x-surebet-mobile">
                <p className="m-0 m-0 text-success fw-bold fs-5">{data[value]['main']['profit']} <>{newKeySymbol.includes(value) ?<sup className="text-warning">New</sup>: "" }</></p>
                <span className="m-0 ms-1 legenda-2-surebet-mobile textGray1-surebet-mobile default-small-font-size">{data[value]['main']['first_home_bet']['sport']}</span>
                <span className="ps-1 m-0 ms-1 legenda-surebet-mobile textGray1-surebet-mobile default-small-font-size">{data[value]['main']['time']}</span>

                {
                    data[value]['main']["first_home_bet"].hasOwnProperty('event_id') ?
                    <span>
                    <img
                        src={estrela}
                        alt="Logo"
                        className="logo-star-surebet-mobile"
                    />
                    </span>
                    :
                    ""
                }
            </div>
            <div className="name-event-surebet-mobile custom-p-x-surebet-mobile">
                <div className="fw-bold fs-title-event-surebet-mobile m-0 p-0 custom-text-break-surebet-mobile">
                    {data[value]['main']['first_home_bet']['title_event']}
                </div>
                <div className="fs-sub-title-event-surebet-mobile custom-text-break-surebet-mobile">
                    {data[value]['main']['first_home_bet']['sub_title_event']}
                </div>
            </div>

            <div className="mt-2 info-surebet-mobile custom-p-x-surebet-mobile">
                {
                    orderLoop.map((nameOrder, indexOrder) => {
                        
                        if (nameOrder in data[value]['main']) {
                            let nameBetHomeModified = data[value]['main'][nameOrder]['name_bet_home'].toLowerCase().replaceAll(' ', '');    
                            return (
                                <div className="info-bet-home-surebet-mobile mt-2">
                                    <div className="d-flex justify-content-between name-datetime-surebet-mobile">
                                        <p className="m-0 fw-bold custom-text-break-surebet-mobile">{nameBetHomeModified in NameBetHomeRenamed ? NameBetHomeRenamed[nameBetHomeModified] : data[value]['main'][nameOrder]['name_bet_home']}</p>
                                        <div className={`d-flex border border-2 ${(warningTimeSurebet.includes(value)) === true ? ' border-danger' : ' border-success'} rounded`}>
                                            <p className="m-0 w-100  px-1 legenda-2-surebet-mobile custom-py-1-surebet-mobile">{data[value]['main'][nameOrder]['date_event']}</p>
                                            <div className={`${(warningTimeSurebet.includes(value)) === true ? 'bg-danger' : 'bg-success'} px-1 w-100 legenda-2-surebet-mobile custom-py-1-surebet-mobile text-light`}>
                                                {data[value]['main'][nameOrder]['time_event']}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <p className="m-0 custom-text-break-surebet-mobile text-start">
                                                    {
                                                        data[value]['main'][nameOrder].hasOwnProperty('description_data_market') ?
                                                            <p className={`${statusMode ? "textGray2" : ""} m-0 data-market-0-surebet-mobile`}>
                                                                {styleDataMarket[data[value]['main'][nameOrder]['data_market']['type']]['componentFunction'](data[value]['main'][nameOrder]['data_market'], data[value]['main'][nameOrder]['description_data_market'])}

                                                            </p>
                                                            :

                                                            <div className={`d-flex flex-column d-flex flex-column justify-content-center h-100`}>
                                                                <p
                                                                    className={`m-0 ${statusMode ? "textGray2" : ""} data-market-surebet-mobile`}
                                                                    dangerouslySetInnerHTML={{ __html: data[value]['main'][nameOrder]['data_market'].replace("<abbr", '<abbr  class="title text-start text-decoration-none" ').replace("minorc", "legenda-2-surebet-mobile").replace("minor", "legenda-2-surebet-mobile text-secondary").replace("<sup", '<sup class="legenda-2-surebet-mobile"') }}
                                                                />
                                                            </div>


                                                    }
                                                </p>
                                            </div>

                                           
                                            <div className="ms-1 icon-interrogation me-3"
                                            data-tooltip-id={`tooltip_${nameOrder}_${value}`}
                                            data-tooltip-content={ data[value]['main'][nameOrder]['title'] === undefined ? data[value]['main'][nameOrder]['description_data_market']: data[value]['main'][nameOrder]['title']}
                                            >
                                                <svg className="h-100" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64" xmlSpace="preserve">
                                                    <g className='draw-icon-interrogation' id="Question-mark">
                                                        <circle cx="31.9781132" cy="50.3625984" r={2} fill="#000000" style={{ fill: 'rgb(255, 255, 255)' }} />
                                                        <path d="M31.5331993,13.3799c-6.2685986,0-10.1620865,3.8936005-10.1620865,10.1620998c0,0.5527,0.4471874,1,1,1 c0.5526867,0,1-0.4473,1-1c0-5.1875,2.9745865-8.1620998,8.1620865-8.1620998c7.8759995,0,9.0517006,5.6718998,9.0517006,9.0527 c0,3.2880993-2.7020988,7.0293007-6.285099,8.7012005c-4.0556889,1.8915977-3.3466873,8.5283012-3.3145008,8.8096008 c0.0576992,0.5107002,0.4902992,0.8866997,0.9922009,0.8866997c0.0380974,0,0.0761986-0.0020027,0.1142998-0.0068016 c0.5487976-0.0625,0.9433136-0.5577011,0.8798981-1.1064987c-0.0058975-0.0546989-0.5478973-5.5009995,2.1738014-6.7705002 c4.3105011-2.0116997,7.4393997-6.4336014,7.4393997-10.5137005C42.5848999,17.5116997,38.4531136,13.3799,31.5331993,13.3799z" fill="#000000" style={{ fill: 'rgb(255, 255, 255)' }} />
                                                        <path d="M32,0c-17.6730995,0-32,14.3268995-32,32s14.3268995,32,32,32s32-14.3269005,32-32S49.673111,0,32,0z M32,62 c-16.542099,0-30-13.457901-30-30c0-16.5419998,13.4578991-30,30-30c16.5419998,0,30,13.4580002,30,30 C62,48.542099,48.5419998,62,32,62z" fill="#000000" style={{ fill: 'rgb(255, 255, 255)' }} />
                                                    </g>
                                                </svg>
                                            </div>
                                            
                                        </div>
                                        <Tooltip id={`tooltip_${nameOrder}_${value}`} data-tooltip-place='top' style={{ maxWidth: '100%' }}/>
                                        <a href={

                                            function(){
                                            let urlExt = GeneratorLinkSurebet(data[value]['main'][nameOrder], data[value]['profit']);
                                            return urlExt;
                                            }()
                                            } target="_blank" className="text-decoration-none fw-bold text-primary">{data[value]['main'][nameOrder]['chance']}</a>
                                    </div>
                                </div>)
                        }
                        else { return ('') }
                    })}

               
            </div>
            <hr />
            <div className="d-flex justify-content-center custom-p-x-surebet-mobile custom-pb-surebet-mobile">


                
                { !statusTrash?
                <button className="btn btn-danger width-btn-surebet-mobile fw-bold custom-radious-surebet-mobile custom-inner-shadow-btn-surebet-mobile w-100"
                onClick={() => eventTrash(value, statusTrash)}
                >
                    
                Excluir
                </button>
                :
                
                <button className="btn btn-primary width-btn-surebet-mobile fw-bold custom-radious-surebet-mobile custom-inner-shadow-btn-surebet-mobile w-100"
                onClick={() => eventTrash(value, statusTrash)}
                >
                    
                Restaurar
                </button>
                }
                <div className="mx-1" />
                <button
                    className="btn btn-success width-btn-surebet-mobile fw-bold bg-btn-access custom-radious-surebet-mobile custom-inner-shadow-btn-surebet-mobile w-100"
                    onClick={() => { handleClick(value); }}
                >
                    Acessar
                </button>
            </div>
        </div>

    );
}

export default LayoutSurebetMobile;