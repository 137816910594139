import React, { useState, useEffect, useRef } from "react";
import { format, differenceInDays, addDays } from "date-fns";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PreEvento from './PreEvento';
import { managerClonerBetHome, timeSurebetAllowed, modelPopUpSettings } from "../global/GlobalVariables";
import { managerFunctionCloneBetHome } from "../global/GlobalFunctions";
import AoVivo from "./AoVivo";
import Cookies from 'js-cookie';
import GeneratorLinkSurebet from "./functions/GeneratorLinkExtension";

// Definição de constantes para os tempos em milissegundos
const timeObject = {
  "6 Horas": 60 * 1000 * 60 * 6,
  "12 Horas": 60 * 1000 * 60 * 12,
  "16 Horas": 60 * 1000 * 60 * 16,
  "1 Dia": 60 * 1000 * 60 * 24,
};

let orderloop = [
  "first_home_bet",
  "second_home_bet",
  "third_home_bet",
  "fourth_home_bet",
  "fifth_home_bet",
  "sixth_home_bet",
  "seventh_home_bet",
  "eighth_home_bet",
  "ninth_home_bet",
  "tenth_home_bet",
];


// Dicionário de links alternativos para sites de apostas

function MainBets(props) {
  // Desestruturação de props
  const {
    valueMin,
    valueMax,
    betHome,
    sports,
    fullData,
    setFullData,
    timeEvent,
    itemsTrash,
    setItemsTrash,
    statusMode,
    controllerLocalStorage,
    setValueMin,
    setValueMax,
    setBetHome,
    quantityBetHomeEnabled,
    setQuantityBetHomeEnabled,
    setSports,
    quantitySportsEnabled,
    setQuantitySportsEnabled,
    setTimeEvent,
    notificationVolume,
    isLoggedIn,
    user,
    onLogout,
    setIsLoggedIn,
    setUserData,
    listBehomeEnabled,
    setlistBehomeEnableds,
    setentradasLigth,
    entradasLigth,
    setentradasPro,
    entradasPro,
    setAparecerProf,
    setAparecerVivo,
    setAparecerLight,
    setAparecerPro,
    AparecerProf,
    AparecerVivo,
    AparecerLight,
    AparecerPro,
    filterOrder,
    setFilterOrder,
    requiredBetHome,
    setRequiredBetHome
  } = props;
  const [apitofisrt, setApitoFirst] = useState(false);
  const [userDatas, setUsersData] = useState(user);
  const iconelixeira = `${process.env.PUBLIC_URL}/images/iconlixeira.png`;
  const [settingsPopUpsJSON, setSettingsPopUpsJSON] = useState(localStorage.getItem('popUpSettings') !== null ? localStorage.getItem('popUpSettings') : JSON.stringify(modelPopUpSettings));

  // Entradas Ligth e Pro

  const [checkUpdate, setCheckUpdate] = useState("1");
  const [checkOpen, setCheckOpen] = useState("1");

  const [data, setData] = useState([]);
  const [newKeySymbol, setNewKeys] = useState([]);
  const oldData = useRef({});
  const [statusTrash, setStatusTrash] = useState(false);
  const [permissaoaovivo, setPermissaoaovivo] = useState(false);

  const [colunaAtiva, setColunaAtiva] = useState("preEvento"); // Inicialmente, definimos a coluna pré-evento como ativa
  const [diasRestantes, setdiasRestantes] = useState(0);
  const [popUpBloqueado, setPopUpBloqueado] = useState(false);
  const [notificationTime, setNotificationTime] = useState(null);
  const navigate = useNavigate();
  const [url, setUrl] = useState(""); // Estado para armazenar a URL
  const playSound = useRef(true);

  const URL_SAFE_BET = "https://arbitragem.vps.webdock.cloud/safe_bet_master";
  const URL_DADOSPRE = "https://arbitragem.vps.webdock.cloud/safe_pro_master";


  const [oldEntradasLight, setoldEntradasLight] = useState(false);
  const [oldEntradasPro, setoldEntradasPro] = useState(false);
  const [warningTimeSurebet, setWarningTimeSurebet] = useState([]);

  // FUNÇÕES UTILIDADES SITE =============================#########################===================================
  //Altera entre PRE EVENTO e AO VIVO

  const toggleColuna = (coluna) => {
    setColunaAtiva(coluna);

    let planName = String(
      userDatas.tipo_de_plano.replace("Arbritagem Bet ", "")
    );

    const partes = planName.split(" ");

    const tipo = partes[0].trim();

    if (tipo === "Light" || tipo.includes("Ao Vivo")) {
      setPermissaoaovivo(true);
    }
  };

  // Função para notificar a aposta
  function notifyBet(bet) {
    const song = `${process.env.PUBLIC_URL}/apitodefutebol.mp3`;
    const audioElement = new Audio(song);

    const playNotificationSound = () => {
      // Defina o volume do áudio com base no estado notificationVolume
      audioElement.volume = notificationVolume;

      audioElement.play().catch((error) => {
        console.error("Erro ao reproduzir o som:", error);
      });
    };

    if (playSound.current === true) {

      playSound.current = false;
      playNotificationSound()
      if ("Notification" in window) {
        const currentTime = format(new Date(), "HH:mm:ss");
        setNotificationTime(currentTime);
      }

      setTimeout(() => {
        playSound.current = true;

      }, 3000);
    }
  }




  // Função para verificar se uma aposta está habilitada e notificar apenas apostas novas
  function checkBetHomeEnabledAndNotify(game) {
    const betHomeEnabled = JSON.parse(localStorage["listBehomeEnabled"]);

    if (typeof game === "undefined") {
      return false;
    }

    for (
      let indexOrderLoop = 0;
      indexOrderLoop < orderloop.length;
      indexOrderLoop++
    ) {
      let keyOrderBethome = orderloop[indexOrderLoop];



      if (keyOrderBethome in game) {
        let betHomeLower = game[keyOrderBethome]["name_bet_home"].toLowerCase();

        let betHomeIsEnable = betHomeEnabled.includes(
          betHomeLower
        );

        if (betHomeIsEnable === false) {
          return false;
        }
      }
    }

    return true;
  }

  // FUNÇÕES para abrir uma janela pop-up do calculadora
  function openPopUpCalculator(value) {
    localStorage.removeItem('dataCalculator');
    if (typeof data !== 'string') {
      localStorage.setItem('dataCalculator', JSON.stringify(data))
    }
    else {
      localStorage.setItem('dataCalculator', data);
    }
    const url = `${window.location.origin}/calculator/${value}`;
    const windowName = "popupCalculator"; // Nome da janela pop-up
    //if (window[windowName] !== undefined) {
    //  window[windowName].close();
    //}
    // Verifique se a janela pop-up está aberta ou se é a primeira vez
    if (window[windowName] && !window[windowName].closed) {
      // Se estiver aberta, atualize o URL da janela existente

      // Adicione um manipulador de eventos para recarregar a página quando o hash do URL for alterado




      window[windowName].onhashchange = function () {
        window[windowName].location.reload();
      };

      window[windowName] = window.open(url, windowName, 'popup');
      window[windowName].focus(); // Coloca a janela pop-up em primeiro plano
      return true;
    } else {
      // Se não estiver aberta, abra uma nova janela pop-up

      const windowFeatures = "width=800,height=460";

      window[windowName] = window.open(url, windowName, windowFeatures);


      if (!window[windowName]) {
        setPopUpBloqueado(true);
        alert(
          "Pop-up bloqueado! Por favor, permita pop-ups para este site nas configurações do seu navegador."
        );
      }
      window[windowName].focus(); // Coloca a janela pop-up em primeiro plano

      return true;
    }
  }
  function openPopUpBet(value) {
    const url = `${value}`;
    const windowName = "popupWindow"; // Nome da janela pop-up
    const windowName2 = "popupWindow2"; // Nome da janela pop-up

    // Verifique se a janela pop-up já existe
    if (
      window[windowName] &&
      !window[windowName].closed &&
      window[windowName2] &&
      !window[windowName2].closed
    ) {
      // Atualize a URL da janela pop-up existente
      if (checkUpdate === "1") {
        window[windowName].location.href = url;
        setCheckUpdate("2");
      } else {
        window[windowName2].location.href = url;
        setCheckUpdate("1");
      }
    } else {
      if (checkOpen === "1") {
        // Se não houver uma janela pop-up aberta, abra uma nova
        setCheckOpen("2");
        const windowFeatures = "width=800,height=460";
        window[windowName] = window.open(url, windowName, windowFeatures);
        if (!window[windowName]) {
          setPopUpBloqueado(true);
          alert(
            "Pop-up bloqueado! Por favor, permita pop-ups para este site nas configurações do seu navegador."
          );
        }
      }
      if (checkOpen === "2") {
        setCheckOpen("1");
        // Se não houver uma janela pop-up aberta, abra uma nova
        const windowFeatures = "width=800,height=460";
        window[windowName2] = window.open(url, windowName2, windowFeatures);
        if (!window[windowName2]) {
          setPopUpBloqueado(true);
          alert(
            "Pop-up bloqueado! Por favor, permita pop-ups para este site nas configurações do seu navegador."
          );
        }
      }
    }
  }


  useEffect(() => {
    localStorage.setItem('popUpSettings', settingsPopUpsJSON);
  }, [settingsPopUpsJSON])

  function openPopUpAllBet(value, value2) {
    const url = `${value}`;
    const url2 = `${value2}`;
    const windowName = "popupWindowAll"; // Nome da janela pop-up
    const windowName2 = "popupWindowAll2"; // Nome da janela pop-up

    let widthPopUpOne = 800;
    let heightPopUpOne = 460;
    let leftPopUpOne = 0;
    let topPopUpOne = 0;

    let widthPopUpTwo = 800;
    let heightPopUpTwo = 460;
    let leftPopUpTwo = 0;
    let topPopUpTwo = 0;


    try {

      let popUpSettings = JSON.parse(settingsPopUpsJSON);
      widthPopUpOne = popUpSettings[windowName]['width'];
      heightPopUpOne = popUpSettings[windowName]['height'];
      leftPopUpOne = popUpSettings[windowName]['left'];
      topPopUpOne = popUpSettings[windowName]['top'];

      widthPopUpTwo = popUpSettings[windowName2]['width'];
      heightPopUpTwo = popUpSettings[windowName2]['height'];
      leftPopUpTwo = popUpSettings[windowName2]['left'];
      topPopUpTwo = popUpSettings[windowName2]['top'];
    } catch {
      setSettingsPopUpsJSON(JSON.stringify(modelPopUpSettings));

      widthPopUpOne = 800;
      heightPopUpOne = 460;
      leftPopUpOne = 0;
      topPopUpOne = 0;
      widthPopUpTwo = 800;
      heightPopUpTwo = 460;
      leftPopUpTwo = 0;
      topPopUpTwo = 0;
    }

    // Verifique se a janela pop-up já existe
    if (
      window[windowName] &&
      !window[windowName].closed &&
      window[windowName2] &&
      !window[windowName2].closed
    ) {


      window[windowName] = window.open('about:blank', windowName, `popup`);
      window[windowName2] = window.open('about:blank', windowName2, `popup`);


      setTimeout(() => {



        try {
          widthPopUpOne = window[windowName].innerWidth;
          heightPopUpOne = window[windowName].innerHeight;
          leftPopUpOne = window[windowName].screenX;
          topPopUpOne = window[windowName].screenY;

          widthPopUpTwo = window[windowName2].innerWidth;
          heightPopUpTwo = window[windowName2].innerHeight;
          leftPopUpTwo = window[windowName2].screenX;
          topPopUpTwo = window[windowName2].screenY;
          let popUpSettings = { 'popupWindowAll': {}, 'popupWindowAll2': {} };
          //let popUpSettings = JSON.parse(localStorage.popUpSettings);

          popUpSettings[windowName]['width'] = widthPopUpOne;
          popUpSettings[windowName]['height'] = heightPopUpOne;
          popUpSettings[windowName]['left'] = leftPopUpOne;
          popUpSettings[windowName]['top'] = topPopUpOne;

          popUpSettings[windowName2]['width'] = widthPopUpTwo;
          popUpSettings[windowName2]['height'] = heightPopUpTwo;
          popUpSettings[windowName2]['left'] = leftPopUpTwo;
          popUpSettings[windowName2]['top'] = topPopUpTwo;

          setSettingsPopUpsJSON(JSON.stringify(popUpSettings));
        }
        catch {
          console.log('Erro');


        }
        window[windowName].close()
        window[windowName2].close()

        window[windowName] = window.open(url, windowName, `width=${widthPopUpOne},height=${heightPopUpOne}, top=${topPopUpOne},left=${leftPopUpOne}`);
        window[windowName2] = window.open(url2, windowName2, `width=${widthPopUpTwo},height=${heightPopUpTwo}, top=${topPopUpTwo},left=${leftPopUpTwo}`);

      }, 3000)

      return;
    } else {

      window[windowName] = window.open(url, windowName, `width=${widthPopUpOne},height=${heightPopUpOne}, top=${topPopUpOne},left=${leftPopUpOne}`);
      window[windowName2] = window.open(url2, windowName2, `width=${widthPopUpTwo},height=${heightPopUpTwo}, top=${topPopUpTwo},left=${leftPopUpTwo}`);
      if (!window[windowName2] || !window[windowName]) {
        setPopUpBloqueado(true);
        alert(
          "Pop-up bloqueado! Por favor, permita pop-ups para este site nas configurações do seu navegador."
        );
      }
      window[windowName].focus();
      window[windowName2].focus();


      // Listen for messages from the popup window

      return;
    }
  }


  function checkBetHomeEnabled(game) {
    const betHomeEnabledString = JSON.parse(localStorage["listBehomeEnabled"]);
    let listBetHomeRequired = [];

    if (typeof game === "undefined") {
      return false;
    }
    if (betHomeEnabledString) {
      const betHomeEnabled = betHomeEnabledString;

      for (
        let indexOrderLoop = 0;
        indexOrderLoop < orderloop.length;
        indexOrderLoop++
      ) {
        let keyOrderBethome = orderloop[indexOrderLoop];

        if (keyOrderBethome in game) {


          let nameBetHome = game[keyOrderBethome]["name_bet_home"].toLowerCase();

          if (betHomeEnabled.includes("betboo")) {
            if (nameBetHome === "betboo") {
              game[keyOrderBethome]["name_bet_home"] = "Betboo";
              game[keyOrderBethome]["url_bet_home"] = game[keyOrderBethome]["url_bet_home"].replace(".sportingbet.", ".br.betboo.");
              game[keyOrderBethome]["url_bet"] = game[keyOrderBethome]["url_bet"].replace(".sportingbet.", ".br.betboo.");
            }
          }

          else if (betHomeEnabled.includes("sportingbet")) {
            if (nameBetHome === "betboo") {
              game[keyOrderBethome]["name_bet_home"] = "Sportingbet";

              game[keyOrderBethome]["url_bet_home"] = game[keyOrderBethome]["url_bet_home"].replace(".br.betboo.", ".sportingbet.");
              game[keyOrderBethome]["url_bet"] = game[keyOrderBethome]["url_bet"].replace(".br.betboo.", ".sportingbet.");
            }
          }

          else if (betHomeEnabled.includes("bwin")) {
            if (nameBetHome === "betboo") {
              game[keyOrderBethome]["name_bet_home"] = "Bwin";

              game[keyOrderBethome]["url_bet_home"] = game[keyOrderBethome]["url_bet_home"].replace(".br.betboo.", ".bwin.");
              game[keyOrderBethome]["url_bet"] = game[keyOrderBethome]["url_bet"].replace(".br.betboo.", ".bwin.");
            }
          }

          else if (betHomeEnabled.includes("boddog (en)")) {
            if (nameBetHome === "boddog") {
              game[keyOrderBethome]["url_bet_home"] = game[keyOrderBethome][
                "url_bet_home"
              ].replace("/basketball/", "/basquete/");
              game[keyOrderBethome]["url_bet_home"] = game[keyOrderBethome][
                "url_bet"
              ].replace("/superleague/", "/superliga/");
            }
          }

          let nameBetHomeLower = nameBetHome;
          if (managerClonerBetHome['allBetHomeAbleToClone'].includes(nameBetHomeLower)) {

            for (let i = 0; i < managerClonerBetHome[nameBetHomeLower]['allBetHomeClone'].length; i++) {
              let nameBetHomeToClone = managerClonerBetHome[nameBetHomeLower]['allBetHomeClone'][i];

              if (betHomeEnabled.includes(nameBetHomeToClone) && !betHomeEnabled.includes(nameBetHomeLower)) {

                let actualURLBet = game[keyOrderBethome]["url_bet"];

                let newURLBet = managerFunctionCloneBetHome(
                  managerClonerBetHome,
                  nameBetHomeLower,
                  nameBetHomeToClone,
                  actualURLBet
                );


                const urlString = managerClonerBetHome[nameBetHomeLower][nameBetHomeToClone]['baseURL'];
                const url = new URL(urlString);
                const origin = url.origin;

                game[keyOrderBethome]["url_bet_home"] = origin;
                game[keyOrderBethome]["url_bet"] = newURLBet;
                game[keyOrderBethome]["name_bet_home"] = managerClonerBetHome[nameBetHomeLower][nameBetHomeToClone]['nameBetHome'];
                break;
              }

            }
          }

          let betHomeIsEnable = betHomeEnabled.includes(nameBetHome);
          if (betHomeIsEnable === false) {
            return false;
          }

          let betHomeLower = nameBetHome.toLowerCase();
          listBetHomeRequired.push(
            requiredBetHome.includes(
              betHomeLower
            )
          );
        }
      }

      if (requiredBetHome.length > 0) {
        return listBetHomeRequired.includes(true);
      } else {
        return true;
      }
    } else {
      console.error("A chave listBehomeEnabled não existe ou está vazia.");
    }
  }


  function checkQuantityBetEnabled(hideBets, filterBetHome) {
    let countBetHide = -1;
    let keySurebet = Object.keys(hideBets);
    for (
      let indexhideBets = 0;
      indexhideBets < keySurebet.length;
      indexhideBets++
    ) {
      let hideBet = hideBets[keySurebet[indexhideBets]];

      countBetHide += checkBetHomeEnabled(hideBet["main"]) === true ? 1 : 0;
    }
    return countBetHide;
  }

  // Função para gerenciar a exibição de apostas
  function managerBetView(fullGame, gameMain = []) {
    let listGames = gameMain.length > 0 ? gameMain : fullGame["group_events"];

    let resultBetHomeChecked = checkBetHomeEnabled(fullGame["main"]);

    let quantityHideBet = 0;
    let textHideBet = "";
    if (listGames) {
      for (
        let indexhideBets = 0;
        indexhideBets < listGames.length;
        indexhideBets++
      ) {
        let hideBet = listGames[indexhideBets]["main"];

        resultBetHomeChecked = checkBetHomeEnabled(hideBet);
        if (resultBetHomeChecked) {
          quantityHideBet = checkQuantityBetEnabled(fullGame["group_events"]);
          if (quantityHideBet > 0) {
            textHideBet = `+ ${quantityHideBet} aposta segura para este evento`;
          }

          fullGame["main"] = hideBet;
          fullGame["main"]["similar_event"] = textHideBet;
          return fullGame;
        }
      }
    }
    return {};
  }

  // Função para exibir apostas em casa
  function betHomeView(fullGame) {
    let bet = managerBetView(fullGame, [fullGame]);
    if (Object.keys(bet).length === 0) {
      bet = managerBetView(fullGame, []);
    }
    return bet;
  }

  //Função para validar a categoria do plano
  const validarCategoria = (categoria) => {
    // Implementar a lógica de validação aqui
    // Por exemplo, verificar se a categoria contém palavras-chave específicas
    // e ajustá-la para se adequar ao padrão desejado, se necessário

    const opcoesPlanos = {
      mensallight: "Arbritagem Bet Light Mensal",
      mensalpro: "Arbritagem Bet Pro Mensal",
      mensalaovivo: "Arbritagem Bet Ao vivo Mensal",
      mensalprofissional: "Arbritagem Bet Profissional Mensal",
      trimestrallight: "Arbritagem Bet Light Trimestral",
      trimestralpro: "Arbritagem Bet Pro Trimestral",
      trimestralaovivo: "Arbritagem Bet Ao vivo Trimestral",
      trimestralprofissional: "Arbritagem Bet Profissional Trimestral",
      anuallight: "Arbritagem Bet Light Anual",
      anualpro: "Arbritagem Bet Pro Anual",
      anualaovivo: "Arbritagem Bet Ao vivo Anual",
      anualprofissional: "Arbritagem Bet Profissional Anual",
    };
    return opcoesPlanos[categoria] || "Inativo";
  };

  // Função para gerenciar a lixeira de eventos
  function eventTrash(nameEvente, statusEvent) {
    if (!statusEvent) {
      setItemsTrash((current) => [...current, nameEvente]);
    } else {
      setItemsTrash(itemsTrash.filter((item) => item !== nameEvente));
    }
  }

  //Função para tratar a URL dos links
  function handleClick(value) {
    // ... Outras lógicas ...



    let allUrlSurebet = {};

    try {

      // Loop para processar URLs
      for (let i = 0; i < orderloop.length; i++) {
        let itemOrderLoop = orderloop[i];
        if (itemOrderLoop in data[value]["main"]) {
          allUrlSurebet[itemOrderLoop] = GeneratorLinkSurebet(data[value]["main"][itemOrderLoop], data[value]["main"]["profit"]);
        }

      }


      openPopUpAllBet(allUrlSurebet['first_home_bet'], allUrlSurebet['second_home_bet']);

      openPopUpCalculator(value);
    } catch (error) {
      console.error("Ocorreu um erro:", error);
    }
  }

  // Função para lidar com o clique e atualizar a URL
  const handleLinkClick = (value, nameOrder) => {
    const betHomeEnabled = JSON.parse(localStorage["listBehomeEnabled"]);
    let novaUrl = data[value]["main"][nameOrder]["url_bet"];

    // Verificações e manipulação da URL conforme necessário
    if (novaUrl.includes("/en/")) {
      novaUrl = novaUrl.replace("/en/", "/pt/");
    }
    if (betHomeEnabled.includes("betboo")) {
      novaUrl = novaUrl.replace(".sportingbet.", ".br.betboo.");
    } else if (betHomeEnabled.includes("sportingbet")) {
      novaUrl = novaUrl.replace(".br.betboo.", ".sportingbet.");
    } else if (betHomeEnabled.includes("bwin")) {
      novaUrl = novaUrl.replace(".br.betboo.", ".bwin.");
    } else if (betHomeEnabled.includes("kto")) {
      novaUrl = novaUrl.replace(".kto", ".estrelabet.");
    } else if (betHomeEnabled.includes("bodog (en)")) {
      novaUrl = novaUrl.replace("/basketball/", "/basquete/"); // Altere a URL para Bwin
      novaUrl = novaUrl.replace("/superleague/", "/superliga/"); // Altere a URL para Bwin
    }

    // Define o estado com a nova URL
    setUrl(novaUrl);
  };



  //FUNÇÃO PRINCIPAL PARA O TRATAMENTO DE DADOS
  const getResponse = async (fullData, newValueData) => {
    for (let keyObject in fullData) {
      let valueObject = fullData[keyObject];

      let newValueDataObject = betHomeView(valueObject);

      if (Object.keys(newValueDataObject).length === 0) {
        continue;
      }

      let profit = newValueDataObject["main"]["profit"];

      // Verificar se 'profit' é uma string antes de usar 'replace'
      if (typeof profit === "string") {
        profit = profit.replace("%", "").replace(",", ".");
      }

      // Se 'profit' não for uma string, converter para string ou tratar de outra maneira
      else {
        // Aqui você pode decidir como lidar com 'profit' se não for uma string
        // Por exemplo, convertendo para string ou simplesmente usando como está se for um número
        profit = profit.toString();
      }

      // Converter 'profit' para um número (float), se necessário
      profit = parseFloat(profit);

      if (!(valueMin <= profit && profit <= valueMax)) {
        if (valueMax < 0) {
          setValueMax(valueMax * -1);
        }
        continue;
      } else if (
        !JSON.parse(localStorage.listSportsEnabled).includes(
          newValueDataObject["main"]["first_home_bet"]["sport"].toLowerCase()
        )
      ) {
        continue;
      } else if (timeEvent !== "Qualquer Horário") {
        let jumpLoop = false;
        for (let i = 0; i < orderloop.length; i++) {
          let order = orderloop[i];

          if (order in newValueDataObject["main"]) {
            let timeGame = parseInt(
              newValueDataObject["main"][order]["time_milliseconds"]
            );
            let timeCalculated = timeGame - new Date().getTime();

            if (timeCalculated >= timeObject[timeEvent]) {
              jumpLoop = true;
              break;
            }
          }
        }
        if (jumpLoop) {
          continue;
        }
      }
      if (itemsTrash.includes(keyObject) === !statusTrash) {
        continue;
      }

      let check = checkBetHomeEnabledAndNotify(newValueDataObject["main"]);
      if (!check) {
        break;
      }
      newValueData[keyObject] = newValueDataObject;
    }

    let newObjectOrdenated = {};
    let objectKeyProfit = {};
    let listProfitToOrdenate = [];
    let keysObject = Object.keys(newValueData);

    for (let i = 0; i < keysObject.length; i++) {


      let keyObject = (newValueData[keysObject[i]]["main"]["profit"]).toString().replaceAll(',', '.').replaceAll("%", '')

      if (objectKeyProfit[Number(keyObject).toString()] === undefined) {
        objectKeyProfit[Number(keyObject).toString()] = []
      }

      objectKeyProfit[Number(keyObject).toString()].push(keysObject[i]);

      listProfitToOrdenate.push(Number(keyObject));

    }

    listProfitToOrdenate.sort(function (a, b) { return a - b; }).reverse();

    for (let i = 0; i < listProfitToOrdenate.length; i++) {
      let keyValueProfit = listProfitToOrdenate[i].toString();
      newObjectOrdenated[objectKeyProfit[keyValueProfit][0]] = fullData[objectKeyProfit[keyValueProfit][0]];
      objectKeyProfit[keyValueProfit].splice(0, 1);
    }


    setData(newObjectOrdenated)
    return newObjectOrdenated;
  };

  // FUNÇÕES UTILIDADES SITE =============================#########################===================================

  // REQUISIÇÃO PARA VPS =============================#########################===================================

  //FUNÇÃO PRINCIPAL DE REQUISIÇÃO DE DADOS

  // Função auxiliar para fazer a requisição GET
  async function getData(url) {

    try {
      const b4726486 = Cookies.get('b4726486');
      const response = await axios.post(url,
        {
          'b652242': b4726486
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        });
      if (response.status !== 200) throw new Error("Falha na requisição");
      try {

        return JSON.parse(response.data);
      }
      catch {
        return response.data;
      }
    } catch (error) {
      console.log("Erro", error);
    }
  }

  // Função auxiliar para verificar o tempo
  function checkTime(dataBet) {
    const allTime = Object.values(dataBet).map((bet) =>
      Number(bet?.time_milliseconds)
    );
    return allTime.some((time) =>
      allTime.some((otherTime) => Math.abs(time - otherTime) > 900000)
    );
  }
  const fetchData = async (LimparDadosOld) => {
    try {
      if (!entradasLigth && !entradasPro) {
        setData([]);
        return;
      }

      const newData = {};

      if (LimparDadosOld) {
        oldData.current.value = JSON.stringify({});
        setData([]);
        setFullData([]);
      }
      const oldDataJsonConvertted = JSON.parse(oldData.current.value || "{}");
      const valueObjects = {};

      if (entradasLigth && (AparecerPro || AparecerLight || AparecerProf)) {
        Object.assign(newData, await getData(URL_SAFE_BET));
      }

      if (entradasPro && (AparecerPro || AparecerProf)) {
        Object.assign(newData, await getData(URL_DADOSPRE));
      }
      if (JSON.stringify(newData) === JSON.stringify(oldDataJsonConvertted))
        return;


      Object.keys(newData).forEach((key) => {
        if (!oldDataJsonConvertted.hasOwnProperty(key)) return;
        const dataBet = oldDataJsonConvertted[key]?.main || {};

        if (checkTime(dataBet)) {
          oldDataJsonConvertted[key]?.main &&
            (oldDataJsonConvertted[key].main.bgTime = true);
        }

        Object.entries(oldDataJsonConvertted[key]?.group_events || {}).forEach(
          ([subKey, subValue]) => {


            if (
              subValue.main.profit === dataBet.profit &&
              subValue.main.first_home_bet.name_bet_home ===
              dataBet.first_home_bet.name_bet_home &&
              subValue.main.second_home_bet.name_bet_home ===
              dataBet.second_home_bet.name_bet_home
            )
              return;
            const subValueMain = subValue.main.profit;
            const newTitle = subValue.main.first_home_bet.title_event;
            const FirstName = subValue.main.first_home_bet.name_bet_home;
            const SecondName = subValue.main.second_home_bet.name_bet_home;

            const newSubKey = subValueMain.replace("%", "").replace(",", "") + newTitle.replace(/[^\w\s]/gi, "").replace(/\s+/g, "") + FirstName.replace(/[^\w\s]/gi, "").replace(/\s+/g, "") + SecondName.replace(/[^\w\s]/gi, "").replace(/\s+/g, "");

            subValue.main.other_key_bet = subValueMain;
            valueObjects[newSubKey] = subValue;

            valueObjects[newSubKey].group_events = {};
          }
        );
      });

      const combinedData = {
        ...oldDataJsonConvertted,
        ...newData,
        ...valueObjects,
      };



      let all_keys_sb = Object.keys(combinedData);

      for (let i = 0; i < all_keys_sb.length; i++) {
        let key_sb = all_keys_sb[i];




        let main_data_surebet = combinedData[key_sb]['main']

        if (main_data_surebet !== undefined && 'date_time' in main_data_surebet) {




          let time_milliseconds_saved = 0;


          if (localStorage.getItem('t4630564433') !== null) {

            let t4630564433 = JSON.parse(localStorage.getItem('t4630564433'));
            time_milliseconds_saved = t4630564433
          } else {

            let actual_milliseconds = new Date().getTime();
            localStorage.setItem('t4630564433', JSON.stringify(actual_milliseconds))
            time_milliseconds_saved = actual_milliseconds;
          }
          time_milliseconds_saved -= ((1000 * 60) * 30)
          const date = new Date(main_data_surebet['date_time']);
          const timeInMilliseconds = date.getTime();
          let actual_milliseconds = new Date().getTime();

          let partEquation = actual_milliseconds - timeInMilliseconds;
          let temporaryTimeLaunch = (partEquation) / (60 * 1000);

          if (time_milliseconds_saved < timeInMilliseconds) {



            let timeLaunch = temporaryTimeLaunch > 60 ? `${parseInt(temporaryTimeLaunch / 60)} h` : `${parseInt(temporaryTimeLaunch)} min`;
            combinedData[key_sb]['main']['time'] = timeLaunch;
            continue;
          }
          else {
            delete combinedData[key_sb];

          }
        }


      }
      let allKeysCombinedData = Object.keys(combinedData);
      let listSurebetToWarnBytime = [];
      for (let i = 0; i < allKeysCombinedData.length; i++) {
        let actualKeySurebet = allKeysCombinedData[i];
        let valueSurebet = combinedData[actualKeySurebet];

        if ('main' in valueSurebet) {
          let allTimeInMilliSeconds = [];
          let mainInfo = valueSurebet['main'];
          for (let indexOrderLoop = 0; indexOrderLoop < orderloop.length; indexOrderLoop++) {
            let order = orderloop[indexOrderLoop];

            if ((order in mainInfo) && ('time_milliseconds' in mainInfo[order])) {
              allTimeInMilliSeconds.push(Number(mainInfo[order]['time_milliseconds']))
            }
          }

          if (allTimeInMilliSeconds.length > 0) {
            allTimeInMilliSeconds.sort((a, b) => b - a);
            let lastIndexAallTimeInMilliSeconds = (allTimeInMilliSeconds.length - 1);

            let firstValueFromAallTimeInMilliSeconds = allTimeInMilliSeconds[0];
            let lastValueFromAallTimeInMilliSeconds = allTimeInMilliSeconds[lastIndexAallTimeInMilliSeconds];

            let calcLimitTime = firstValueFromAallTimeInMilliSeconds - lastValueFromAallTimeInMilliSeconds;
            if (calcLimitTime >= timeSurebetAllowed) {
              listSurebetToWarnBytime.push(actualKeySurebet);
            }
          }
        }
      }
      setWarningTimeSurebet(listSurebetToWarnBytime)
      setFullData(combinedData);
      oldData.current.value = typeof newData !== 'string' ? JSON.stringify(newData) : newData;

      let newKeys = Object.keys(newData);
      let oldKeys = Object.keys(oldDataJsonConvertted);
      let storageKeys = [];
      let listKeysToRemove = [];

      for (let i = 0; i < newKeys.length; i++) {
        let valueKey = newKeys[i];
        if (oldKeys.includes(valueKey) === false) {
          storageKeys.push(valueKey);


        }
      }
      for (let i = 0; i < oldKeys.length; i++) {
        let valueKey = oldKeys[i];
        if (newKeys.includes(valueKey) === false) {
          listKeysToRemove.push(valueKey);
        }
      }
      for (let i = 0; i < listKeysToRemove.length; i++) {
        delete oldDataJsonConvertted[listKeysToRemove[i]];
      }

      for (let i = 0; i < storageKeys.length; i++) {
        oldDataJsonConvertted[storageKeys[i]] = newData[storageKeys[i]];
      }

      setNewKeys((prevArray) => [...prevArray, ...storageKeys]);

      setTimeout(function () {
        for (
          let indexNewKeyAdded = 0;
          indexNewKeyAdded < storageKeys.length;
          indexNewKeyAdded++
        ) {
          setNewKeys((keys) => {
            return keys.filter(
              (item) => item !== storageKeys[indexNewKeyAdded]
            );
          });
        }
      }, 10000);
    } catch (error) {
      console.error("Erro na requisição: " + error.message);
    }
  };

  //CARREGAR DADO DO USUARIO
  const loadUsers = () => {


    if (userDatas === null || userDatas === undefined) {

      window.location.reload();


    } else {

      return axios.post("https://arbitragem.bet:8081/login", {
        email: userDatas.email,
        senha: userDatas.senha,
      })
        .then((response) => {
          setUsersData(response.data);
        }).catch;
    }
  };

  // Funções simplificadas para carregar usuários e cupons
  const loadData = async (url, setState) => {
    try {
      const response = await axios.get(url);
      setState(response.data);
    } catch (error) {
      console.error("Erro ao carregar dados:", error);
    }
  };

  const handleChangePlan = (userId, categoria) => {
    // Verificação e ajuste da categoria antes de enviar
    let categoriaValidada = validarCategoria(categoria);

    // Axios POST request
    axios
      .post("https://arbitragem.bet:8081/alterplano", {
        userid: userId,
        newplans: categoriaValidada,
      })
      .then((response) => {
        loadData("https://arbitragem.bet:8081/users", setUsersData); // Recarregar a lista de usuários após a atualização
      })
      .catch((error) => {
        console.error("Erro ao criar o cupom:", error);
      });
  };

  // Função para enviar os dados para o servidor
  const saveUserPreferences = async () => {
    try {
      await axios.post(
        "https://arbitragem.bet:8081/saveuserpreference",
        {
          // Coloque seus dados aqui, por exemplo:
          id: user.id,
          userPreferences: {
            modo: statusMode,
            ValueMin: JSON.parse(localStorage["profitMin"]),
            ValueMax: JSON.parse(localStorage["profitMax"]),
            BetHomes: betHome,
            QtdEnableBet: quantityBetHomeEnabled,
            sports: sports,
            QtdEnableSport: quantitySportsEnabled,
            setTtempo: timeEvent,
            volume: notificationVolume,
            listBehomeEnabled: quantityBetHomeEnabled,
            listSportsEnabled: quantitySportsEnabled,
            login: true,
          },
        }
      );
    } catch (error) {
      console.error("Erro ao enviar dados:", error);
    }
  };

  // Checar a Sessão do usuario
  async function checkSessiondID(sessionIDs) {
    if (sessionIDs) {
      const concurrentLoginResponse = await axios.post(
        "https://arbitragem.bet:8081/api/check-concurrent-session",
        {
          userID: sessionIDs, // Use o ID retornado com sucesso
        }
      );
      const checkdata = concurrentLoginResponse.data.concurrentLogin;

      if (!checkdata) {

        setTimeout(() => {
          let actualUserData2 = JSON.parse(localStorage.userData);
          if (sessionIDs === actualUserData2.sessionID) {
            setIsLoggedIn(false);
            saveUserPreferences();
            setUserData(null);
            setUsersData(null);
            onLogout();
          }
        }, 10000);
        return;
      }
    } else {
      return;
    }
  }
  //  REQUISIÇÃO PARA VPS =============================#########################===================================

  //TODOS OS 'useEffect' COLOCAR ABAIXO =============================#########################===================================

  // Efeito para filtrar e ordenar dados
  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      loadUsers();
      saveUserPreferences();

      try {
        let actualUserData = JSON.parse(localStorage.userData);
        checkSessiondID(actualUserData.sessionID);
      }
      catch {
        window.location.reload();
      }
      // Verifique   se os valores mudaram
      if (
        oldEntradasLight !== entradasLigth ||
        oldEntradasPro !== entradasPro
      ) {


        setoldEntradasLight(entradasLigth);
        setoldEntradasPro(entradasPro);

        fetchData(true);
      } else {

        fetchData(false);
      }

      if (userDatas.status_da_conta === "Inativo") {
        const userdatasnew = {
          sessionID: "",
          id: "",
          nome: "",
          email: "",
          senha: "",
          plano: "",
          tipo_de_plano: "",
          status_da_conta: "",
          username: "",
          phone: "",
          cuponuser: null,
          data_cadastro: "",
          data_pagamento: null,
          data_expiracao: null,
        };

        handleChangePlan(userDatas.id, "Inativo");
        setIsLoggedIn(false);
        setUserData(null);
        setUsersData(null);
        window.localStorage.setItem("userData", JSON.stringify(userdatasnew));
        onLogout();
      } else {
        if (userDatas.tipo_de_plano.includes("Pro ")) {
          setAparecerPro(true);
          setAparecerLight(true);
        }
        if (userDatas.tipo_de_plano.includes("Light")) {
          setentradasPro(false);
          setAparecerLight(true);
        }
        if (userDatas.tipo_de_plano.includes("Vivo")) {
          setAparecerVivo(true);
        }
        if (userDatas.tipo_de_plano.includes("Prof")) {
          setAparecerProf(true);
        }
      }
    }, 1000);

    return () => clearInterval(fetchDataInterval);
    // eslint-disable-next-line
  }, [userDatas, entradasLigth, entradasPro]);

  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      if (popUpBloqueado) {
        setPopUpBloqueado(false);
      }
    }, 60000);

    return () => clearInterval(fetchDataInterval);
  }, [popUpBloqueado]);

  useEffect(() => {
    let newValueData = {};

    if (fullData && Object.keys(fullData).length > 0) {
      getResponse(fullData, newValueData);

      if (!apitofisrt) {
        setTimeout(() => {
          setApitoFirst(true);
        }, 10000);
      }
    }
    localStorage.setItem("trash", JSON.stringify(itemsTrash));
    // eslint-disable-next-line
  }, [
    fullData,
    itemsTrash,
    statusTrash,

  ]
  );

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [notificationTime, notificationVolume, isLoggedIn]);

  useEffect(() => {
    window.addEventListener("beforeunload", saveUserPreferences);
    window.addEventListener("unload", saveUserPreferences);

    return () => {
      window.removeEventListener("beforeunload", saveUserPreferences);
      window.removeEventListener("unload", saveUserPreferences);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    statusMode
      ? (document.body.style.backgroundColor = "#0d0d0d")
      : (document.body.style.backgroundColor = "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusMode]);

  // Efeito para verificar se o usuário deve ser deslogado automaticamente
  useEffect(() => {
    // Supondo que user.data_pagamento é uma string no formato ISO8601
    if (userDatas) {
      const dataPagamento = new Date(userDatas.data_pagamento);

      // Função para extrair a duração do plano com base na descrição
      const extrairDuracaoDoPlano = (descricaoPlano) => {
        if (descricaoPlano.toLowerCase().includes("mensal")) {
          return 30; // Se a descrição incluir "mensal", assume uma duração de 30 dias
        } else if (descricaoPlano.toLowerCase().includes("trimestral")) {
          return 90; // Se a descrição incluir "trimestral", assume uma duração de 90 dias
        } else if (descricaoPlano.toLowerCase().includes("anual")) {
          return 365; // Se a descrição incluir "anual", assume uma duração de 365 dias
        } else {
          return 0; // Se não encontrar uma correspondência, assume 0 dias (plano indefinido)
        }
      };

      // Obtém a duração com base na descrição do plano do usuário
      const duracaoPlano = extrairDuracaoDoPlano(userDatas.tipo_de_plano);
      if (userDatas.data_expiracao != null) {
        const dataExpiracao = addDays(new Date(userDatas.data_expiracao), 0);

        const dataAtual = new Date();

        // Calcula os dias restantes com base na data de expiração e na data atual
        const diasRestantes = differenceInDays(dataExpiracao, dataAtual) + 1;

        setdiasRestantes(diasRestantes);

        if (diasRestantes <= 0 || userDatas.status_da_conta === "Inativo") {
          handleChangePlan(userDatas.id, "Inativo");
          setIsLoggedIn(false);
          saveUserPreferences();
          setUserData(null);
          setUsersData(null);
          onLogout();
          return;
        }
      } else if (userDatas.data_expiracao == null) {
        // Calcula a data de expiração do plano
        const dataExpiracao = addDays(dataPagamento, duracaoPlano);

        const dataAtual = new Date();

        // Calcula os dias restantes com base na data de expiração e na data atual
        const diasRestantes = differenceInDays(dataExpiracao, dataAtual);

        setdiasRestantes(diasRestantes);

        if (diasRestantes <= 0 || userDatas.status_da_conta === "Inativo") {
          handleChangePlan(userDatas.id, "Inativo");
          setIsLoggedIn(false);
          saveUserPreferences();
          setUserData(null);
          setUsersData(null);
          onLogout();
          return;
        }
      }
    }
    // eslint-disable-next-line
  }, [diasRestantes]);

  return (
    <div
      className={`fontsizemainbet w-100 border border-2 mt-3 ${statusMode ? "borderColor" : ""
        } rounded p-0 d-block mx-2`}
    >
      <Row>
        <Col className="borderstyle">
          <div
            className={`py-2 border-bottom ${statusMode ? "borderColor textGray" : ""
              } fw-bold text-center `}
            onClick={() => toggleColuna("preEvento")}
          >
            <button
              className={`text-title-apostas py-2 border-bottom ${statusMode ? "borderColor textGray" : ""
                } fw-bold text-center `}
              onClick={() => toggleColuna("preEvento")}
            >
              ARBITRAGENS PRÉ-EVENTO
            </button>
          </div>
        </Col>
        <Col className="borderstyle">
          <div
            className={`py-2 border-bottom ${statusMode ? "borderColor textGray" : ""
              } fw-bold text-center `}
            onClick={() => {
              toggleColuna("eventoAoVivo");
            }}
          >
            <button
              className={`text-title-apostas py-2 border-bottom ${statusMode ? "borderColor textGray" : ""
                } fw-bold text-center `}
              onClick={() => {
                toggleColuna("eventoAoVivo");
              }}
            >
              ARBITRAGENS AO VIVO
            </button>
          </div>
        </Col>
        {popUpBloqueado && (
          <div
            style={{
              padding: "20px",
              color: "white",
              background: "red",
              textAlign: "center",
              fontSize: "2rem",
            }}
          >
            <p>Pop-up bloqueado! Libere os pop-up do site na parte superior.</p>
          </div>
        )}
      </Row>
      <Row>
        {colunaAtiva === "preEvento" && (

          <PreEvento
            statusTrash={statusTrash}
            setStatusTrash={setStatusTrash}
            iconelixeira={iconelixeira}
            controllerLocalStorage={controllerLocalStorage}
            valueMin={valueMin}
            setValueMin={setValueMin}
            valueMax={valueMax}
            setValueMax={setValueMax}
            betHome={betHome}
            setBetHome={setBetHome}
            quantityBetHomeEnabled={quantityBetHomeEnabled}
            setQuantityBetHomeEnabled={setQuantityBetHomeEnabled}
            sports={sports}
            setSports={setSports}
            data={data}
            quantitySportsEnabled={quantitySportsEnabled}
            setQuantitySportsEnabled={setQuantitySportsEnabled}
            timeEvent={timeEvent}
            setTimeEvent={setTimeEvent}
            setlistBehomeEnableds={setlistBehomeEnableds}
            newKeySymbol={newKeySymbol}
            openPopUpCalculator={openPopUpCalculator}
            eventTrash={eventTrash}
            openPopUpBet={openPopUpBet}
            handleClick={handleClick}
            statusMode={statusMode}
            notificationTime={notificationTime}
            setNotificationTime={setNotificationTime}
            setentradasLigth={setentradasLigth}
            entradasLigth={entradasLigth}
            setentradasPro={setentradasPro}
            entradasPro={entradasPro}
            AparecerProf={AparecerProf}
            AparecerVivo={AparecerVivo}
            AparecerLight={AparecerLight}
            AparecerPro={AparecerPro}
            notifyBet={notifyBet}
            filterOrder={filterOrder}
            setFilterOrder={setFilterOrder}
            warningTimeSurebet={warningTimeSurebet}
            requiredBetHome={requiredBetHome}
            setRequiredBetHome={setRequiredBetHome}
          />

        )}
        {colunaAtiva === "eventoAoVivo" && (

          <AoVivo
            statusTrash={statusTrash}
            setStatusTrash={setStatusTrash}
            iconelixeira={iconelixeira}
            controllerLocalStorage={controllerLocalStorage}
            valueMin={valueMin}
            setValueMin={setValueMin}
            valueMax={valueMax}
            setValueMax={setValueMax}
            betHome={betHome}
            setBetHome={setBetHome}
            quantityBetHomeEnabled={quantityBetHomeEnabled}
            setQuantityBetHomeEnabled={setQuantityBetHomeEnabled}
            sports={sports}
            setSports={setSports}
            data={data}
            quantitySportsEnabled={quantitySportsEnabled}
            setQuantitySportsEnabled={setQuantitySportsEnabled}
            timeEvent={timeEvent}
            setTimeEvent={setTimeEvent}
            setlistBehomeEnableds={setlistBehomeEnableds}
            newKeySymbol={newKeySymbol}
            apitofisrt={apitofisrt}
            notifyBet={notifyBet}
            openPopUpCalculator={openPopUpCalculator}
            eventTrash={eventTrash}
            orderloop={orderloop}
            openPopUpBet={openPopUpBet}
            listBehomeEnabled={listBehomeEnabled}
            url={url}
            handleLinkClick={handleLinkClick}
            handleClick={handleClick}
            permissaoaovivo={permissaoaovivo}
            statusMode={statusMode}
            user={user}
            filterOrder={filterOrder}
            setFilterOrder={setFilterOrder}
            requiredBetHome={requiredBetHome}
            setRequiredBetHome={setRequiredBetHome}
            notificationVolume={notificationVolume}
            setentradasLigth={setentradasLigth}
            entradasLigth={entradasLigth}
            setentradasPro={setentradasPro}
            entradasPro={entradasPro}
            AparecerProf={AparecerProf}
            AparecerVivo={AparecerVivo}
            AparecerLight={AparecerLight}
            AparecerPro={AparecerPro}
          />

        )}
      </Row>
    </div>
  );
}

export default MainBets;
